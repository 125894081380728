<template>
    <span>
        <span
            v-if="resource.state == 'Active' && resource.schedulers && resource.schedulers.length > 0"
            v-b-tooltip.hover
            title="Pause"
        >
            <i
                class="pause-resource icon-control-pause pointer-action"
                :disabled="isViewer"
                @click="pauseResource(resource)"
                v-bind:class="{'pointer-disabled': isViewer}"
            ></i
        ></span>
        <span v-else-if="resource.state == 'Paused'" v-b-tooltip.hover title="Activate">
            <i
                class="unpause-resource icon-control-play pointer-action"
                :disabled="isViewer"
                @click="unpauseResource(resource)"
                v-bind:class="{'pointer-disabled': isViewer}"
            ></i
        ></span>
    </span>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        resource: {
            required: true,
            type: Object,
        },
        pauseResource: {
            required: true,
            type: Function,
        },
        unpauseResource: {
            required: true,
            type: Function,
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
    },
}
</script>

<style>
.pause-resource,
.unpause-resource {
    font-weight: 600;
}
</style>
