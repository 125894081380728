<template>
    <div class="workflow-report-table__wrapper">
        <wit-table
            mode="server"
            :fields="columns"
            :request-function="requestFunction"
            striped
            hide-per-page-selector
            ref="workflowReportTable"
            :default-per-page="10"
            class="report-list-table"
            infinite-scroll
        >
            <template v-slot:cell(success)="{item}">
                <span class="success-thumb">
                    <b-spinner v-if="item.status === 'Active'" class="spinner-running"></b-spinner>
                    <i v-else-if="item.status === 'Succeeded'" class="icon fa fa-check success" />
                    <i v-else class="icon fa fa-times failed" />
                </span>
            </template>
            <template v-slot:cell(actions)="{item}">
                <span v-b-tooltip.hover title="Show Details">
                    <i @click="retrieveDetails(item)" class="actions fa fa-info-circle" />
                </span>
                <span v-if="item.status === 'Active'" v-b-tooltip.hover title="Stop Job">
                    <i @click="stopJob(item)" class="actions fa fa-pause" />
                </span>

                <span v-if="item.success === false" v-b-tooltip.hover title="Show Errors">
                    <i class="actions fa fa-exclamation-triangle" @click="showErrorModal(item)" />
                </span>
            </template>
        </wit-table>

        <WitModal v-model="workflowErrorsModal.show" title="Workflow Errors" variant="danger" size="lg">
            <wit-table
                :items="workflowErrorsModal.messages"
                :mode="errorsTable.mode"
                :fields="errorsTable.fields"
                striped
                paginated
                hide-per-page-selector
                :ref="errorsTable.ref"
                :default-per-page="errorsTable.perPage"
                :class="errorsTable.class"
            ></wit-table>
        </WitModal>
    </div>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'
import WitTable from '@/components/WitTable.vue'
import Loading from '@/components/loading.vue'
import moment from 'moment'

export default {
    components: {
        Loading,
        WitTable,
        WitModal,
    },
    props: {
        filterId: {
            type: String,
            default: () => null,
        },
    },
    data: () => {
        return {
            workflowErrorsModal: {
                show: false,
                messages: [],
            },
            errorsTable: {
                ref: 'workflowErrorsTable',
                class: 'report-list-table',
                mode: 'client',
                fields: ['Date', 'Task name', 'Error message'],
                perPage: 10,
            },
            reportError: {
                state: true,
                message: null,
            },
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/workflow`,
            columns: ['startedAt', 'finishedAt', 'duration', 'status', 'success', 'actions'],
            reloadTableInterval: null,
        }
    },
    mounted() {
        this.reloadTableInterval = setInterval(this.reloadTable, 10000)
    },
    destroyed() {
        clearInterval(this.reloadTableInterval)
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios
                .get(`${this.apiUrl}/executions`, {
                    params: {
                        workflowId: this.filterId,
                        projectId: this.$store.state.project.active.id,
                        limit: params.limit,
                        cursorId: params.cursorId ? params.cursorId : undefined,
                    },
                })
                .catch(
                    function (e) {
                        this.dispatch('error', e)
                    }.bind(this)
                )

            return {
                data: response.data.data.map((element) => {
                    return {
                        ...element,
                        startedAt: this.formatDate(element.startTime),
                        finishedAt: this.formatDate(element.endTime),
                        duration: this.formatDuration(element.duration),
                        status: this.parseStatus(element.state),
                    }
                }),
                cursorId: response.data.metadata.newCursorId,
            }
        },

        reloadTable() {
            if (!document.hidden && this.$refs.workflowReportTable) this.$refs.workflowReportTable.refreshScroll()
        },

        formatDuration(duration) {
            if (!duration) return duration

            const total = parseInt(duration.replace('s', ''))
            const hours = Math.floor(total / 3600)
            const minutes = Math.floor((total % 3600) / 60)
            const remainingSeconds = Math.floor(total % 60)

            const formattedHours = String(hours).padStart(2, '0')
            const formattedMinutes = String(minutes).padStart(2, '0')
            const formattedSeconds = String(remainingSeconds).padStart(2, '0')

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        },

        formatSecondsToDate(dateTime) {
            if (!dateTime) return dateTime

            const {seconds} = dateTime
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
        },

        formatDate(dateTime) {
            if (!dateTime) return dateTime

            return moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
        },

        parseStatus(status) {
            return status
                .split('_')
                .map((str) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase())
                .join(' ')
        },

        parseLocalDate(_date) {
            const date = new Date(_date)
            return `${date.toLocaleDateString('pl-PL')} ${date.toLocaleTimeString('pl-PL')}`
        },

        retrieveDetails(item) {
            this.$projectRouter.push(`/workflow/report/${item.id}?workflowId=${this.filterId}`)
        },

        async stopJob(item) {
            this.$store.commit('loading/PROCESSING', `Stopping jobs...`)

            await this.axios.post(`${this.apiUrl}/stop`, {workflowId: this.filterId, executionId: item.id})

            this.reloadTable()

            setTimeout(() => this.$store.commit('loading/PROCESSED'), 1000)
        },

        showErrorModal(item) {
            this.workflowErrorsModal.show = true
            this.workflowErrorsModal.messages = item.errors.map((msg) => {
                return {
                    Date: msg.workflowRunDate.value,
                    'Task name': msg.resourceName,
                    'Error message': msg.errorMsg,
                }
            })
        },

        stripName(name) {
            return name.replace(/ - __WITID__.*/gi, '')
        },
    },
}
</script>

<style lang="scss">
.actions {
    font-size: 1.1rem;
    padding: 0 0.35rem;
    cursor: pointer;
}
.success {
    color: green;
}
.warning {
    color: #ffc32b;
}
.failed {
    color: red;
}

.magnifier-action {
    font-weight: 600;
    padding: 0 1rem;
    color: #4b4b4c;
}
.retrieve-more-action {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.3rem;
    color: #4b4b4c;
}
.disabled-retrieve-more-action {
    color: #858586;
}
.spinner-running {
    height: 0.875rem;
    width: 0.875rem;
    color: black !important;
    border-width: 0.2em;
}
.spinner-running-success-true {
    color: green !important;
}
.spinner-running-success-false {
    color: red !important;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover {
    background-color: transparent;
}
</style>
