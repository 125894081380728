<template>
    <div class="animated fadeIn workflow-list">
        <b-card class="workflow-list__create-card">
            <b-row class="piplines-list-header">
                <b-col md="2">
                    <project-router-link to="/workflow/create">
                        <b-button
                            id="add-new-workflow-button"
                            :disabled="isViewer"
                            class="text-nowrap"
                            variant="primary"
                        >
                            <i class="icon-plus mr-1"></i>Add new Workflow
                        </b-button>
                    </project-router-link>
                </b-col>
            </b-row>
        </b-card>
        <b-tabs fill v-model="tabIndex" class="wit-tabs">
            <b-tab title="User-created">
                <wit-table
                    mode="server"
                    :search="true"
                    :fields="columns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="userWorkflowTable"
                    id="workflow-list"
                    :default-per-page="10"
                >
                    <template v-slot:cell(state)="{item}">
                        <PropState :state="item.state" />
                    </template>
                    <template v-slot:cell(scheduled)="{item}">
                        <PropScheduled :schedulers="item.schedulers" />
                    </template>

                    <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                    <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>

                    <template v-slot:cell(actions)="{item}" class="button-row">
                        <div class="button-row">
                            <DetailsAction class="workflow-details-button" :routerLink="'/workflow/' + item.id" />
                            <PauseOrUnpauseAction
                                class="workflow-pause-button"
                                :pauseResource="pauseWorkflow"
                                :unpauseResource="unpauseWorkflow"
                                :resource="item"
                            />
                            <RunAction class="workflow-run-again-button" :resource="item" :run="runWorkflow" />
                            <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                        </div>
                    </template>
                    <template v-slot:row-details="{item}">
                        <WorkflowReportTable :filterId="item.id"></WorkflowReportTable>
                    </template>
                </wit-table>
            </b-tab>

            <b-tab title="Auto-generated">
                <wit-table
                    mode="server"
                    :search="true"
                    :fields="columns"
                    :request-function="autoRequestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="autoWorkflowTable"
                    id="workflow-list"
                    :default-per-page="10"
                >
                    <template v-slot:cell(state)="{item}">
                        <PropState :state="item.state" />
                    </template>
                    <template v-slot:cell(scheduled)="{item}">
                        <PropScheduled :schedulers="item.schedulers" />
                    </template>

                    <template v-slot:cell(createdAt)="{item}"> {{ formatDate(item.createdAt) }} </template>
                    <template v-slot:cell(updatedAt)="{item}"> {{ formatDate(item.updatedAt) }} </template>

                    <template v-slot:cell(actions)="{item}" class="button-row">
                        <div class="button-row">
                            <DetailsAction class="workflow-details-button" :routerLink="'/workflow/' + item.id" />
                            <PauseOrUnpauseAction
                                class="workflow-pause-button"
                                :pauseResource="pauseWorkflow"
                                :unpauseResource="unpauseWorkflow"
                                :resource="item"
                            />
                            <RunAction class="workflow-run-again-button" :resource="item" :run="runWorkflow" />
                            <RemoveAction :removeResource="showRemoveModal" :resource="item" />
                        </div>
                    </template>
                    <template v-slot:row-details="{item}">
                        <WorkflowReportTable :filterId="item.id"></WorkflowReportTable>
                    </template>
                </wit-table>
            </b-tab>
        </b-tabs>

        <RemoveRecordModal
            v-model="showModal"
            :removeElement="removeWorkflow"
            :recordId="removeRecord"
            @close="removeRecord = null"
        >
            <div slot="error">
                <Feedback :state="workflowError.state" :invalid="workflowError.message" />
            </div>
        </RemoveRecordModal>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import moment from 'moment'
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import RemoveRecordModal from '@/components/Modals/RemoveRecordModal.vue'
import PropScheduled from '@/components/PropScheduled.vue'
import PropState from '@/components/PropState.vue'
import WitTable from '@/components/WitTable.vue'
import WorkflowReportTable from '@/components/WorkflowReportTable.vue'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import PauseOrUnpauseAction from '@/components/Actions/PauseOrUnpauseAction.vue'
import RemoveAction from '@/components/Actions/RemoveAction.vue'
import RunAction from '@/components/Actions/RunAction.vue'

export default {
    components: {
        DetailsAction,
        Feedback,
        Loading,
        PauseOrUnpauseAction,
        PropScheduled,
        PropState,
        RemoveAction,
        RemoveRecordModal,
        RunAction,
        WitTable,
        WorkflowReportTable,
    },
    notifications: {
        workflowCreated: {
            type: VueNotifications.types.success,
            title: 'Workflow has been successfully created.',
        },
        workflowUpdated: {
            type: VueNotifications.types.success,
            title: 'Workflow has been successfully updated.',
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        isViewer() {
            return this.activeProject.role == 'Viewer'
        },
    },
    mounted() {
        this.reloadTableInterval = setInterval(this.reloadTable, 5000)
    },
    destroyed() {
        clearInterval(this.reloadTableInterval)
    },
    data() {
        return {
            tabIndex: 0,
            searchUrl: `${process.env.VUE_APP_SEARCH_HOST}/search/workflow`,
            apiUrl: `${process.env.VUE_APP_NODE_API_HOST}/workflow`,
            columns: ['name', 'state', 'scheduled', 'createdAt', 'updatedAt', 'actions'],
            showModal: false,
            removeRecord: null,
            workflowError: {
                state: null,
                message: null,
            },
        }
    },
    methods: {
        async requestFunction(requestParams) {
            const {query, limit: length, limit, page} = requestParams
            const offset = (page - 1) * length
            const filter = `autoCreated=0`
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset, filter} : {projectId, ...requestParams, autoCreated: false}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },
        async autoRequestFunction(requestParams) {
            const {query, limit: length, limit, page} = requestParams
            const offset = (page - 1) * length
            const filter = `autoCreated=1`
            const projectId = this.$store.state.project.active.id

            const apiUrl = query ? this.searchUrl : this.apiUrl
            const params = query ? {query, length, offset, filter} : {projectId, ...requestParams, autoCreated: true}

            try {
                const response = await this.axios.get(apiUrl, {params})

                return {
                    data: response.data.data,
                    count: response.data.metadata.count,
                }
            } catch (error) {
                this.dispatch('error', error)
            }
        },
        reloadTable() {
            if (!document.hidden && this.$refs.userWorkflowTable) this.$refs.userWorkflowTable.fetchData({silent: true})
            if (!document.hidden && this.$refs.autoWorkflowTable) this.$refs.autoWorkflowTable.fetchData({silent: true})
        },
        pauseWorkflow(workflow) {
            this.$store.commit('loading/PROCESSING', `Pausing...`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/workflow/pause`, {
                    workflowId: workflow.id,
                })
                .then((response) => {
                    this.workflowError = {}
                    this.reloadTable()
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.workflowError.state = false
                    this.workflowError.message = exception.response.data.data
                })
        },
        unpauseWorkflow(workflow) {
            this.$store.commit('loading/PROCESSING', `Activating...`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/workflow/unpause`, {
                    workflowId: workflow.id,
                })
                .then((response) => {
                    this.workflowError = {}
                    this.reloadTable()
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.workflowError.state = false
                    this.workflowError.message = exception.response.data.data
                })
        },
        runWorkflow(workflow) {
            this.$store.commit('loading/PROCESSING', `Starting...`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/workflow/run`, {
                    workflowId: workflow.id,
                })
                .then((response) => {
                    this.workflowError = {}
                    this.reloadTable()
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.workflowError.state = false
                    this.workflowError.message = exception.response.data.data
                })
        },
        removeWorkflow() {
            this.workflowError.state = null
            this.workflowError.message = null

            const workflowId = this.removeRecord.id

            this.$store.commit('loading/PROCESSING', `Removing the Workflow...`)
            this.axios
                .delete(`${process.env.VUE_APP_NODE_API_HOST}/workflow/${workflowId}`)
                .then((response) => {
                    this.showModal = false
                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    this.$store.commit('loading/PROCESSED')
                    this.workflowError.state = false
                    this.workflowError.message = exception.response.data.data
                })
        },
        showRemoveModal(workflow) {
            this.removeRecord = workflow
            this.showModal = true
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
}
</script>

<style lang="scss">
.workflow-list {
    &__create-card {
        .card-body {
            padding: 0.75rem 1rem;
        }
        background-color: #f0f3f5;
    }
}
</style>
