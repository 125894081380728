<template>
    <span>{{ friendlyCron }}</span>
</template>

<script>
import cronstrue from 'cronstrue'

export default {
    props: {
        schedulers: {
            type: Array,
            default: () => [],
        },
        locale: {
            type: String,
            default: 'en',
        },
    },
    computed: {
        friendlyCron: {
            get() {
                if (this.schedulers && this.schedulers.length > 0) {
                    const cronStrings = this.schedulers.map((scheduler, index) => {
                        const cronString = cronstrue.toString(scheduler.schedule, {locale: this.locale})
                        if (index > 0 && index === this.schedulers.length - 1)
                            return `, and ${cronString.charAt(0).toLowerCase() + cronString.substring(1)}`
                        else if (index > 0) return `, ${cronString.charAt(0).toLowerCase() + cronString.substring(1)}`
                        else return cronString
                    })
                    return cronStrings.join('')
                } else return 'Not scheduled'
            },
        },
    },
}
</script>
