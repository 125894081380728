<template>
    <span v-b-tooltip.hover title="Run Again">
        <i @click="run(resource)" class="icon-reload run-again-resource pointer-action" />
    </span>
</template>

<script>
export default {
    props: {
        resource: {
            required: true,
            type: Object,
        },
        run: {
            type: Function,
            required: true,
        },
    },
}
</script>

<style>
.run-again-resource {
    font-weight: 600;
}
</style>
