<template>
    <span v-if="state == 'Active'">
        <i class="fa fa-check"></i>
        {{ state }}
    </span>
    <span v-else-if="state == 'Paused'">
        <i class="fa fa-pause"></i>
        {{ state }}
    </span>
    <span v-else-if="state && state.includes('...')">
        <b-spinner class="spinner-running" sm label="Spinning" variant="primary"></b-spinner>
        {{ state }}
    </span>
    <span v-else>
        {{ state }}
    </span>
</template>

<script>
export default {
    props: {
        state: {
            type: String,
        },
    },
}
</script>

<style>
.spinner-running {
    height: 0.875rem;
    width: 0.875rem;
    color: black !important;
    border-width: 0.2em;
}
</style>
